import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { LibraryService } from '../library/library.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor(
        private libraryService: LibraryService,
        public auth: AuthService,
    ) {}

    addRow(worksheet: ExcelJS.Worksheet, columna: number, fila: number, datos: any[]) {
        if (datos && Array.isArray(datos)) {
            for (let x = 0; x < datos.length; x++) {
                let address = this.columnaALetra(columna + x) + fila;
                worksheet.getCell(address).value = datos[x] != undefined ? datos[x] : '';
                if (datos[x] != undefined) worksheet.columns[columna + x - 1].width = String(datos[x]).length;
            }
        }
    }

    addRowTitles(worksheet: ExcelJS.Worksheet, columna: number, fila: number, datos: any[], bgColor: string = null) {
        if (datos && Array.isArray(datos)) {
            for (let x = 0; x < datos.length; x++) {
                let address = this.columnaALetra(columna + x) + fila;
                const cell = worksheet.getCell(address);
                cell.value = datos[x] != undefined ? datos[x] : '';
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.font = { bold: true, size: 12 };
                if (datos[x] != undefined) worksheet.columns[columna + x - 1].width = String(datos[x]).length + 4;
                cell.border = {
                    bottom: { style: 'thin', color: { argb: 'black' } },
                    left: { style: 'thin', color: { argb: 'black' } },
                    right: { style: 'thin', color: { argb: 'black' } },
                    top: { style: 'thin', color: { argb: 'black' } },
                };
                if (bgColor != null) this.setBackgroundColor(cell, bgColor);
            }
        }
    }

    addRowValues(worksheet: ExcelJS.Worksheet, columna: number, fila: number, datos: any[], bgColor: string = null) {
        if (datos && Array.isArray(datos)) {
            for (let x = 0; x < datos.length; x++) {
                let address = this.columnaALetra(columna + x) + fila;
                const cell = worksheet.getCell(address);
                cell.value = datos[x] != undefined ? datos[x] : '';
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.font = { bold: false, size: 12 };
                if (datos[x] != undefined) worksheet.columns[columna + x - 1].width = String(datos[x]).length + 4;
                cell.border = {
                    bottom: { style: 'thin', color: { argb: 'black' } },
                    left: { style: 'thin', color: { argb: 'black' } },
                    right: { style: 'thin', color: { argb: 'black' } },
                    top: { style: 'thin', color: { argb: 'black' } },
                };
                if (bgColor != null) this.setBackgroundColor(cell, bgColor);
            }
        }
    }

    generarWorkbook(nombreSheet: string) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(nombreSheet);
        return { workbook, worksheet };
    }

    descargarExcel(workbook: ExcelJS.Workbook, nombreArchivo: string) {
        try {
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, nombreArchivo + '.xlsx');
            });

            this.libraryService.crearNotificacion('Excel generado exitosamente', 'success', 'Archivo generado correctamente.');
        } catch (error) {
            this.libraryService.crearNotificacion('Error al generar Excel', 'error', 'Ocurrió un error al generar el archivo Excel.');
            console.error(error);
        }
    }

    columnaALetra(columna: number): string {
        let temp: any;
        let letra = '';
        while (columna > 0) {
            temp = (columna - 1) % 26;
            letra = String.fromCharCode(temp + 65) + letra;
            columna = (columna - temp - 1) / 26;
        }
        return letra;
    }

    setBackgroundColor(cell: ExcelJS.Cell, color: string, bordear?: boolean) {
        if (cell && color) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color.replace('#', '') },
                bgColor: { argb: 'FFF' },
            };
        }

        if (bordear) {
            cell.border = {
                bottom: { style: 'thin', color: { argb: 'black' } },
                left: { style: 'thin', color: { argb: 'black' } },
                right: { style: 'thin', color: { argb: 'black' } },
                top: { style: 'thin', color: { argb: 'black' } },
            };
        }
    }

    columnWidth(worksheet: ExcelJS.Worksheet, columnAddress: number, width: number) {
        const column = worksheet.getColumn(columnAddress);
        column.width = width;
    }

    verificarValor(valor: any): string {
        return valor ? valor + ' ' : '';
    }

    verificarValorBooleano(valor: boolean): string {
        return valor ? 'Sí' : 'No';
    }

    obtenerFecha(fecha: Date): string {
        return fecha != null ? this.libraryService.convertirFecha(fecha, 'date', 'DD/MM/YYYY') : '';
    }

    verificarUsuario(usuario: any): string {
        return usuario ? this.verificarValor(usuario.nombres) + ' ' + this.verificarValor(usuario.apellidos) : '';
    }

    verificarObjeto(objeto: any): string {
        return objeto ? objeto.nombre : '';
    }

    getDiccionario(tipo: any, array: any[]): string {
        if (!tipo || !array) return '';
        const elemento = array.find(element => element.id == tipo);
        return elemento ? elemento.nombre : '';
    }
}